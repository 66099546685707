// This file will contain all variables, our custom variables and
//those from Ant Design which we want to override.
@import "~antd/lib/style/themes/default.less";

@primary-color: #3ECC80;
@secondary-color: #79D063;

:root {
  --PC: @primary-color;
  --SC: @secondary-color;
}
